import React from 'react';

import styles from './Service.module.css';

function ServiceItem({title, description}) {
  return (
    <div className={styles.serviceItem}>
      <h3>
        {title}
      </h3>
      <p>
        {description}
      </p>
    </div>
  )
}

class Service extends React.PureComponent {
  constructor(props) {
    super(props);
    this._services = [{
      title: 'BRAND IDENTITY',
      description: "Brand identity is what makes you instantly recognizable to your customer. We'll help you to make a brand identity design for your business.",
    },
    {
      title: 'GRAPHIC DESIGN',
      description: "We believe that great graphic design will elevate your business. We create graphic design for client's need as well such as packaging design, company profile, booklet, etc.",
    },
    {
      title: 'DIGITAL',
      description: "Offers you social media design content for your business and more.",
    }]
  }

  render() {
    return (
      <div className={styles.wrapperContainer}>
        <h2>Services</h2>
        <div>
          {this._services.map((service, idx) => {
            return <ServiceItem
              key={`ServiceItem-${idx}`}
              {...service}
            />
          })}
        </div>
      </div>
    )
  }
}

export default Service;