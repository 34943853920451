import React from 'react';
import Slider from "react-slick";
import client01 from '../images/client_01.png';
import client02 from '../images/client_02.png';
import client03 from '../images/client_03.png';
import client04 from '../images/client_04.png';
import client05 from '../images/client_05.png';
import client06 from '../images/client_06.png';
import styles from './Client.module.css';

const CLIENT = [client01, client02, client03, client04, client05, client06];

class Client extends React.PureComponent {
  render() {
    const settings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
      <div className={styles.clientContainer}>
        <h2>CLIENTS</h2>
        <div className={styles.slider}>
          <Slider {...settings}>
            {CLIENT.map((client, clientIdx) => {
              return (
                <div key={`client-${clientIdx}`}>
                  <div className={styles.clientItemContainer}>
                    <img className={styles.clientImage} src={client} key={clientIdx} alt={clientIdx} />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }
}

export default Client;