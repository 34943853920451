import React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollTo } from 'react-scroll-to';
import { graphql } from 'gatsby';

import Project from '../components/Project';
import About from '../components/About';
import Service from '../components/Service';
import Client from '../components/Client';
import Instagram from '../components/Instagram';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import './fonts.css';
import './customStyle.css';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import BannerMenuContainer from '../components/BannerMenuContainer';
import styles from './index.module.css';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  aboutRef = React.createRef();
  projectRef = React.createRef();
  clientRef = React.createRef();
  contactRef = React.createRef();

  handleScroll = () => {
    this.setState({
      scrollY: window.scrollY,
    })
  }

  getOffsetY = (menuName) => {
    let offsetTop;
    switch(menuName) {
      case 'ABOUT':
        offsetTop = this.aboutRef.current.offsetTop;
        break;
      case 'PROJECTS':
        offsetTop = this.projectRef.current.offsetTop;
        break;
      case 'CLIENTS':
        offsetTop = this.clientRef.current.offsetTop;
        break;
      case 'CONTACT':
        offsetTop = this.contactRef.current.offsetTop;
        break;
      default:
        offsetTop = 0;
    }
    return offsetTop;
  }

  handleMenuClick = (scrollTo, menuName) => (e) => {
    const elementOffsetY = this.getOffsetY(menuName);
    scrollTo({
      y: elementOffsetY,
      smooth: true,
    });
  }

  render() {
    return (
      <div>
        <Helmet defaultTitle={`By Inge Salim`}>
          <title>By Inge Salim</title>
          <meta name='og:type' content='website' />
          <meta name='og:site_name' content='ByIngeSalim' />
          <html lang='en' />
          <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no' />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
          <link rel="icon" href="../images/client_06.png"></link>
        </Helmet>
        <div>
          <h1 style={{ display: 'none' }}>By Inge Salim</h1>
          <BannerMenuContainer onMenuClick={this.handleMenuClick} />
          <div ref={this.projectRef}>
            <Project />
          </div>
          <div ref={this.aboutRef}>
            <About />
          </div>
          <Service />
          <div ref={this.clientRef}>
            <Client />
          </div>
          <Instagram />
          <div ref={this.contactRef}>
            <Contact />
          </div>
          <Footer />
        </div>

        <ScrollTo>
          {({ scrollTo }) => {
            return this.state.scrollY > 0 && (
              <div className={styles.iconChevronUpContainer}
                onClick={() => {
                  scrollTo({
                    y: 0,
                    smooth: true
                  })
                }}
                >
                <span className={styles.iconChevronUp} />
              </div>
            )
          }}
        </ScrollTo>
      </div>
    )
  }
}

export default App;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;