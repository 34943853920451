import React from 'react';
import { ScrollTo } from 'react-scroll-to';
import styles from './Menu.module.css';

function MenuItem(props) {
  const handleClick = (scrollTo) => (e) => {
    props.onMenuClick(scrollTo, props.name)(e);
  }

  return (
    <ScrollTo>
      {({ scrollTo }) => (
        <div className={styles.menuItem} onClick={handleClick(scrollTo)}>
          {props.name}
        </div>
      )}
    </ScrollTo>
  )
}

function Menu(props) {
  return (
    <div className={styles.menuWrapper}>
      <div className={styles.menuContainer}>
        <MenuItem onMenuClick={props.onMenuClick} name={`ABOUT`} />
        <MenuItem onMenuClick={props.onMenuClick} name={`PROJECTS`} />
        <MenuItem onMenuClick={props.onMenuClick} name={`CLIENTS`} />
        <MenuItem onMenuClick={props.onMenuClick} name={`CONTACT`} />
      </div>
    </div>
  )
}

export default Menu;