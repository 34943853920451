import React, { useState } from 'react';
import classNames from 'classnames';
import StackGrid from 'react-stack-grid';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import styles from './Project.module.css';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark {
        frontmatter {
          title
          product {
            category
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  // const items = [port1a, port3c, port2b, port1c, port3b, port2c, port1b, port2a, port3a];

  const [active, setActive] = useState({
    branding: true,
    graphic_design: true,
    digital: true,
  });
  return (
    <div className={styles.mainWrapper}>
      <h2>PROJECTS</h2>
      <ul className={styles.menu}>
        <li onClick={() => { setActive({
          ...active,
          branding: !active.branding,
        })}} className={classNames(styles.menuItem, active.branding && styles.active)}>BRANDING</li>
        <li onClick={() => { setActive({
          ...active,
          graphic_design: !active.graphic_design,
        })}} className={classNames(styles.menuItem, active.graphic_design && styles.active)}>GRAPHIC DESIGN</li>
        <li onClick={() => { setActive({
          ...active,
          digital: !active.digital,
        })}} className={classNames(styles.menuItem, active.digital && styles.active)}>DIGITAL</li>
      </ul>
      <StackGrid
        className={styles.stackGridContainer}
        columnWidth={300}
        gutterWidth={16}
        enableSSR
        monitorImagesLoaded
      >
        {data.markdownRemark.frontmatter.product.reduce((result, item, idx) => {
          if (active[item.category]) {
            result.push(
              <div className={styles.gridItem} key={`gridItem-${idx}`}>
                <Image
                  fluid={item.image.childImageSharp.fluid}
                  alt={item.title}
                />
              </div>
            );
          }
          return result;
        }, [])}
      </StackGrid>
    </div>
  )
}