import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './Contact.module.css';

class Contact extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.contactContainer}>
          <div className={styles.idea}>
            <div className={styles.ideaQuestion}>
              <div className={styles.ideaQuestionTop}>
                HAVE AN
              </div>
              <div className={styles.ideaQuestionBottom}>
                IDEA ?
              </div>
            </div>
            <div className={styles.ideaAnswer}>
              start a project
            </div>
          </div>
          <div className={styles.contact}>
            <TextField
              className={styles.textField}
              label="Name"
              margin="dense"
              type="text"
            />
            <TextField
              className={styles.textField}
              label="Email"
              margin="dense"
              type="email"
            />
            <TextField
              className={styles.textField}
              label="Tell me your project"
              multiline
              margin="normal"
            />
            <div className={styles.buttonContainer}>
              <Button variant="contained" color="default" className={styles.button}>
                SEND MESSAGE
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.closing}>
          <p className={styles.colaborate}>or if you want to collaborate with me, you can send email!</p>
          <p>
            <a className={styles.email} href="mailto:hello@byingesalim.com">hello@byingesalim.com</a>
          </p>
        </div>
      </div>
    )
  }
}

export default Contact;