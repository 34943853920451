import React, { useState } from 'react';
import classNames from 'classnames';
import ingeLogo from '../images/client_06.png';
import styles from './MenuMobile.module.css';
import Menu from './Menu';

function MenuMobile(props) {
  const [expand, setExpand] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <a href="http://www.byingesalim.com">
            <img className={styles.ingeLogo} src={ingeLogo} alt="inge logo" />
          </a>
        </div>
        <div className={styles.hamburgerMenu}>
          <a href="#" onClick={() => setExpand(!expand)} className={expand ? styles.active : undefined}>
            <span />
          </a>
        </div>
      </div>
      <div className={classNames(styles.menuContainer, expand ? styles.expand : undefined)}>
        <Menu onMenuClick={props.onMenuClick} />
      </div>
    </div>
  )
}

export default MenuMobile;