import React from 'react';

import styles from './Instagram.module.css';

class Instagram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      instagramPosts: [],
    }
  }

  async componentDidMount() {
    const fetchInstagramTopThreePhotos = async () => {
      return await (await fetch('/.netlify/functions/getInstagramTopThreePhotos')).json();
    }
    const result = await fetchInstagramTopThreePhotos();
    this.setState({
      instagramPosts: result,
    })
  }

  render() {
    return (
      <div className={styles.instagramContainer}>
        {this.state.instagramPosts.map((post, postIndex) => {
          return (
            <a key={`instagram-${postIndex}`} href={post.link} target="_blank" rel="noopener noreferrer">
              <img className={styles.igImg} key={postIndex} alt={post.caption.text.trim()} title={post.caption.text.trim()} src={post.images.standard_resolution.url} />
            </a>
          )
        })}

        <a className={styles.defaultLink} href="https://www.instagram.com/ingesalim/" target="_blank" rel="noopener noreferrer">
          <div className={styles.motto}>
            <div className={styles.mottoTop}>INSTA</div>
            <div className={styles.mottoBottom}>GRAM</div>
            <div className={styles.lineTop}></div>
            <div className={styles.lineBottom}></div>
          </div>
        </a>
      </div>
    )
  }
}

export default Instagram;