import React from 'react';
import Banner from '../components/Banner';
import Menu from '../components/Menu';
import MenuMobile from '../components/MenuMobile';
import styles from './BannerMenuContainer.module.css';

class BannerMenuContainer extends React.PureComponent {
  render() {
    return (
      <div>
        <div className={styles.mobileMenu}>
          <MenuMobile onMenuClick={this.props.onMenuClick} />
        </div>
        <Banner />
        <div className={styles.desktopMenu}>
          <Menu onMenuClick={this.props.onMenuClick} />
        </div>
      </div>
    )
  }
}

export default BannerMenuContainer;