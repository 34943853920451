import React from 'react';
import ingeLogo from '../images/client_06.png';
import ig from '../images/ico_ig.png';
import fb from '../images/ico_fb.png';
import ln from '../images/ico_ln.png';

import styles from './Footer.module.css';

class Footer extends React.PureComponent {
  render() {
    return (
      <div>
        <div className={styles.footerContainer}>
          <div className={styles.email}>
            <a href="mailto:hello@byingesalim.com" className={styles.email}>hello@byingesalim.com</a>
          </div>
          <div className={styles.logo}>
            <a href="http://www.byingesalim.com">
              <img className={styles.ingeLogo} src={ingeLogo} alt="inge logo" />
            </a>
          </div>
          <div className={styles.social}>
            <a href="https://www.instagram.com/ingesalim/" target="_blank" rel="noopener noreferrer">
              <img src={ig} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/inge.salim" target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="facebook" />
            </a>
            <a href="https://www.linkedin.com/in/inge-salim-a8a894157/" target="_blank" rel="noopener noreferrer">
              <img src={ln} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className={styles.copyrightContainer}>
          <div>© Copyright ByIngeSalim. All Rights Reserved</div>
          <div>Developed by <a href="http://www.frendypriandi.com">Frendy Priandi</a></div>
        </div>
      </div>
    )
  }
}

export default Footer;