import React from 'react';

import styles from './About.module.css';

class About extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrapperContainer}>
        <div className={styles.motto}>
          <div className={styles.mottoTop}>WE BUILD</div>
          <div className={styles.mottoBottom}>DESIGN</div>
          <div className={styles.lineTop}></div>
          <div className={styles.lineBottom}></div>
        </div>
        <div className={styles.description}>
          <h3>Hello!</h3>
          <p>
            I'm Inge Salim, a graphic designer based in Bandung - Indonesia.
            I've been designing profesionally since 2013 and am passionate about any graphic design related.
            I work with clients all around the country and all around the world.
            Just contact me for work inquiries!
          </p>
        </div>
      </div>
    )
  }
}

export default About;