import React from 'react';
import styles from './Banner.module.css';

class Banner extends React.PureComponent {
  render() {
    return (
      <div>
        <div className={styles.bg} />
      </div>
    )
  }
}

export default Banner;